var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "full" } },
    [
      _c("h2", { staticStyle: { color: "#636363" } }, [
        _vm._v("Location Lists"),
      ]),
      _c("p", {
        staticClass: "mt-2 mb-4",
        domProps: { innerHTML: _vm._s(_vm.infoLink) },
      }),
      _c(
        "vx-card",
        [
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full mt-4" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "mr-4 sm:mb-0 mb-2",
                    on: { click: _vm.addLocationList },
                  },
                  [_vm._v("Add")]
                ),
              ],
              1
            ),
          ]),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.locationLists,
              gridOptions: _vm.gridOptions,
              autoGroupColumnDef: _vm.autoGroupColumnDef,
              rowSelection: "single",
              pagination: true,
              context: _vm.context,
              suppressPaginationPanel: false,
            },
          }),
        ],
        1
      ),
      _vm.showNameEditor
        ? _c("location-list-name-editor", {
            attrs: { applicationId: _vm.applicationId },
            on: {
              onAddSuccess: _vm.onAddListSuccess,
              onCancel: function ($event) {
                _vm.showNameEditor = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }